<template>
  <v-container class="body pa-0">
    <v-row
      align="end"
      justify="end"
      no-gutters
      class="body-inner d-flex flex-column"
      ref="bodyInner"
      >
     
      <div no-gutters class="message mb-1" transition="slide-y-reverse-transition"
          v-for="(item, index) in messages" 
          v-bind:key="index" 
          :class="{ 'text-left align-start': item.author === 'bot', 'text-right align-end': item.author !== 'bot' }"
        >
        <div no-gutters v-if="item.author === 'bot'" class="bot d-flex flex-row">
          <v-avatar  size="44px" class="mr-1 chat-bot-avatar">
          <img v-if="item.message_index == 0" :src="bot_setting.icon">
          </v-avatar>
          <span v-if="item.message_index == 0 && item.type == 'text' && item.value != ''" 
            class="ml-3 arrow"
          ></span>
          <v-flex md12 class="ml-3 mb-auto">
            <p v-if="item.type == 'text'" class="mb-0 message-inner" ><span style="white-space: pre-wrap;" v-html="item.value"></span></p>
            <v-img v-if="item.type == 'image' && item.value != ''" 
              :src="item.value"
              :lazy-src="item.value"
              height="auto" width="100%" 
              contain
              class="mb-0 pa-0 message-inner"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-flex>
        </div>

        <div no-gutters v-else class="user">
          <span v-if="item.type == 'text' && item.value != ''"
              class="arrow"
              :style="{backgroundColor: bot_setting.main_color}"

          ></span>
          <v-flex md12 class="mr-2 mb-auto">
            <p v-if="item.type == 'text'" class="mb-0 message-inner" :style="{backgroundColor: bot_setting.main_color}"><span style="white-space: pre-wrap;" v-html="item.value"></span></p>
            <v-img v-if="item.type == 'image' && item.value != ''" 
              :src="item.value" 
              height="auto" width="100%" 
              contain
              class="mb-0 message-inner" 
              :style="{backgroundColor: bot_setting.main_color}"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-flex>

        </div>
      </div>
      <div id="wave-message" no-gutters class="message mb-1 text-left align-start" style="display:none;">
        <div no-gutters class="bot d-flex flex-row">
            <v-avatar  size="44px" class="mr-1 chat-bot-avatar">
            <img v-if="bot_setting != null" :src="bot_setting.icon">
            </v-avatar>
            <span class="ml-3 arrow"
            ></span>
            <div  class="ml-3 mb-auto message-inner">
              <div id="wave" >
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
              </div>
            </div>
        </div>
      </div>
      <div id="scroll-to-element" ref="bottomElement" style="width: 100%; height: 1px; background-color: transparent;"></div>
    </v-row>
    <v-row
      align="end"
      justify="center"
      no-gutters
      >
      <v-col cols="12" class="input-field" id="input-field">
        <v-container
          class="input-field-inner"
          align="center"
          v-if="show_input"
        >
          <TextFieldInput 
            v-if="currentItem.answer_type == 'text' || currentItem.answer_type == 'email' || currentItem.answer_type == 'phone-number-no-hyphen' || currentItem.answer_type == 'phone-number-hyphen' || currentItem.answer_type == 'phone-number-both'"

            v-bind:placeholder="currentItem.placeholder"
            v-bind:pattern_match="currentItem.pattern_match"
            v-bind:error_message="currentItem.error_message"
            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:answer_type="currentItem.answer_type"
            v-bind:confirmation="currentItem.confirmation"

            @text_input_submitted="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />
          <MultilineInput 
            v-if="currentItem.answer_type == 'multiline'"

            v-bind:placeholder="currentItem.placeholder"
            v-bind:pattern_match="currentItem.pattern_match"
            v-bind:error_message="currentItem.error_message"
            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:confirmation="currentItem.confirmation"

            @text_input_submitted="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />
          <SingleSelectionInput 
            v-if="currentItem.answer_type == 'selection'"
            v-bind:selection_options="currentItem.default_answer"
            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:confirmation="currentItem.confirmation"

            @single_selection_input_submitted="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />
          
          <MultiSelectionInput 
            v-if="currentItem.answer_type == 'multi_selection'"
            v-bind:placeholder="currentItem.placeholder"
            v-bind:error_message="currentItem.error_message"
            v-bind:selection_options="currentItem.default_answer"
            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:confirmation="currentItem.confirmation"

            @multi_slection_input_submitted="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />

          <DropdownInput 
            v-if="currentItem.answer_type == 'dropdown'"
            v-bind:placeholder="currentItem.placeholder"
            v-bind:error_message="currentItem.error_message"
            v-bind:selection_options="currentItem.default_answer"
            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:label_name="currentItem.label_name"
            v-bind:confirmation="currentItem.confirmation"

            @dropdown_input_submitted="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />
          <DropdownMultiInput 
            v-if="currentItem.answer_type == 'multi_dropdown'"
            v-bind:placeholder="currentItem.placeholder"
            v-bind:error_message="currentItem.error_message"

            v-bind:selection_options="currentItem.default_answer"
            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:label_name="currentItem.label_name"
            v-bind:confirmation="currentItem.confirmation"

            @dropdown_multi_input_submitted="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />
          <AutoAddressByZipcodeInput
            v-if="currentItem.answer_type == 'auto_address_by_zipcode'"
            v-bind:placeholder="currentItem.placeholder"
            v-bind:error_message="currentItem.error_message"
            v-bind:sub_label="currentItem.sub_label"

            v-bind:bot_setting="bot_setting"
            v-bind:is_required="currentItem.required"
            v-bind:confirmation="currentItem.confirmation"

            @auto_address_by_zipcode="inputSubmitted"
            @skip_input_submitted="skipInputSubmitted"
            @calculate_padding="calculatePadding"
          />
        </v-container>
        <!--Progress bar-->
        <v-row v-if="bot_setting && bot_setting.progress_bar">
          <v-progress-linear
            v-if="bot_setting != null && bot_setting && bot_setting.progress_bar"
            v-model="progress_value"
            :color="bot_setting.progress_bar_color"
            height="4"
            class="chat-progress not-active"
          >
          <template v-slot="{ value }">
          <div class="progress-percentage-wrapper">
            <span class="arrow"
              :style="{backgroundColor: bot_setting.main_color}"
              ></span>
            <div class="progress-percentage" 
              :style="{backgroundColor: bot_setting.main_color}"

            ><span class="text">{{ Math.ceil(value) }}%</span></div>
          </div>
          </template>
          </v-progress-linear>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.not-active {
  pointer-events: none;
  cursor: default;
}
.clear { clear: both; }

.chat-progress{
  overflow: visible;
  position: relative;

}

.v-progress-linear__content{
  display: block;
}

.progress-percentage{
  position: absolute;
  color:#FFF;
  display: inline-block;

  width: 40px;
  height: 40px;
  border-radius: 50% 50% 50% 50%;
  bottom: 10px;
  z-index:2;
}

.progress-percentage-wrapper{
  margin-left: 20px;
}

.progress-percentage .text{
  text-align: center;
  display: block;
  font-size: 12px;
  padding-top: 10px;

}
.chat-progress .arrow{
  content: "\00a0";
  display: block;
  height: 15px;
  position: absolute;
  transform:             translateX(37%) translateY(-70%) rotate( 122deg ) skew( -25deg );
      -moz-transform:    translateX(37%) translateY(-70%) rotate( 122deg ) skew( -25deg );
      -ms-transform:     translateX(37%) translateY(-70%) rotate( 122deg ) skew( -25deg );
      -o-transform:      translateX(37%) translateY(-70%) rotate( 122deg ) skew( -25deg );
      -webkit-transform: translateX(37%) translateY(-70%) rotate( 122deg ) skew( -25deg );
  width:  25px;
  bottom: 4px;
  z-index:2;
}

</style>

<script>
import TextFieldInput from './TextFieldInput.vue'
import MultilineInput from './MultilineInput.vue'
import SingleSelectionInput from './SingleSelectionInput.vue'
import MultiSelectionInput from './MultiSelectionInput.vue'
import DropdownInput from './DropdownInput.vue'
import DropdownMultiInput from './DropdownMultiInput.vue'
import AutoAddressByZipcodeInput from './AutoAddressByZipcodeInput.vue'
import chat from '@/services/api/chat.js'

export default {
  name: 'Body',
  components: {
    TextFieldInput, MultilineInput,
    SingleSelectionInput, MultiSelectionInput, DropdownInput, DropdownMultiInput,
    AutoAddressByZipcodeInput
  }, 
  props:['session','flow_chat', 'start_chat', 'bot_setting', 'nolink'],
  
  data: () => ({
    messages: [],

    currentItem: null,
    currentItemIndex: -1,
    show_input: false,

    message_interval: 1000,

    totalQuestion: 0,
    progress_value: 0,

    answer_data: [],
    question_ids_answered: [],
    question_map: [],
  }),

  methods: {
    
    startChat(){
      window.MYCHATBOT['answer_data'] = this.answer_data 
      var self = this

      self.addBotMessages(self.flow_chat.welcome)
        .then(function(){
          self.addQuestion()
        })
    },

    addBotMessages(lineData){
      var self = this
      return new Promise(function(resolve){
        self.addBotMessage_message(lineData, 0).then( function(){resolve()})
      })
    },

    addBotMessage_message(lineData, index){
      var self = this
      return new Promise(function(resolve){
        if (lineData !== undefined && lineData.length > 0 && index < lineData.length){

          new Promise(function(resolve_wave){
            self.showWave()
            var wave_delay = self.message_interval
          
            setTimeout( function() { resolve_wave()
            }, wave_delay) 

          }).then(function(){
            
            // replace tag in reactions
            var message = lineData[index].value
            if( lineData[index].type == 'text' ){
              message = self.replace_tag_in_reactions(lineData[index].value)
            }

            self.hideWave()
            self.addMessage({
                  type: lineData[index].type,
                  value: message,
                  author: 'bot',
                  message_index: index
            })
            index++
            return resolve(self.addBotMessage_message(lineData, index))
          })
        }else{
          resolve()
        }
      })
    },

    addQuestion(){
      var self = this
      return new Promise(function(resolve){
        self.currentItemIndex += 1
        self.updateProgress(self.currentItemIndex)
        if (self.flow_chat.body !== undefined && self.flow_chat.body.length > 0 && self.currentItemIndex < self.flow_chat.body.length){
          
          self.currentItem = self.flow_chat.body[self.currentItemIndex]

          if (!('confirmation' in self.currentItem)){
            self.currentItem.confirmation = {error_message: "", items: []}
          }


          /*****************************/
          // question filter
          if (self.checkQuestionRoute(self.currentItem)){
            // add question message
            self.addBotMessages(self.currentItem.question).then(function(){
              if ('answer' in self.currentItem){
                // add user message
                self.addUserMessage(self.currentItem.answer, false)

                // add common reaction
                self.addBotMessages(self.currentItem.common_reaction).then(function(){
                  // add filter reaction
                  self.addFilterReaction(self.currentItem.filter_reaction).then(function(){
                    return resolve(self.addQuestion())
                  })
                })
              }else{
                self.show_input = true
                self.scrollToEnd()
                resolve(false)
              }
            })
          }else{ 
            return resolve(self.addQuestion())
          }
          /*****************************/
        } else{
          // no questions to anwer, add thank messages
          resolve(true)
        }
      })
    },

    checkQuestionRoute(question){
      //console.log("q id:" + question.id)

      var display = true
      var route = []
      if (!('route' in question)){
        return display
      }else{
        display = true
        route = question.route
      }

      var question_ids_to_check = []  // removing invalid question_id
      if (route.length > 0){
        for(var i = 0; i < route.length ; i++){
          var route_item = route[i]
          if (this.question_map.indexOf(route_item.question_id) >= 0){
            question_ids_to_check.push(route_item.question_id)
          }
        }

        for(var jj = 0; jj < question_ids_to_check.length; jj++){
          var question_id = question_ids_to_check[jj]

          if (this.question_ids_answered.indexOf(question_id) == -1){
            display = false
            return display
          }
        }

        
        var check_array_j = []
        for(var j = this.answer_data.length - 1; j >= 0; j--){
          var answer_item = this.answer_data[j]
          if ( question_ids_to_check.indexOf(answer_item.question_id) >= 0 ) {
            check_array_j[j] = true

            var check_array_k = []
            for(var k = 0; k < route.length ; k++){
              var route_check = route[k]

              if (route_check.question_id == answer_item.question_id){

                check_array_k[k] = true
                var pattern = route_check.value
                if (pattern == ""){
                  pattern = "^$"
                }
                var regex = new RegExp(pattern)
                if (regex.test(answer_item.value)) {
                  check_array_k[k] = true
                }else{
                  check_array_k[k] = false
                }
              }
            }

            var filtered_k = check_array_k.filter(function (el) {
              return el != null
            })
            for(var m = 0; m < filtered_k.length ; m++){
              check_array_j[j] = check_array_j[j] && filtered_k[m]
            }

          }
        }
        var filtered_j = check_array_j.filter(function (el) {
          return el != null
        })
        //console.log(filtered)
        for(var n = 0; n < filtered_j.length ; n++){
          display = display && filtered_j[n]
        }


      }else{
        display = true
      }
      
      //console.log(display)
      return display
    },

    skipInputSubmitted(){
      var self = this
      //console.log(value)
      this.currentItem.answer = ""

      var user_chat_message = "Skip"

      if (this.nolink == 'true'){
        // hide input
        self.show_input = false

        // add user message
        self.addUserMessage(user_chat_message, true)

        // add common reaction
        self.addBotMessages(self.currentItem.common_reaction).then(function(){
          // add filter reaction
          self.addFilterReaction(self.currentItem.filter_reaction).then(function(){
            self.addQuestion().then(function(is){
              if (is == true){
                // add thanks
                self.addBotMessages(self.flow_chat.thanks)
              }
            })
          })
        })
      }else{
        // send answer to server
        var header = {headers: {'Authorization': this.session, 'Content-Type': 'application/json'}}
        var postData = {
                update:{
                  [this.currentItem.id]: this.currentItem.answer
              }
            }
        chat.sendChat( postData, header).then(response => {
          if(response.data.code == 2000){
            // hide input
            self.show_input = false

            // add user message
            self.addUserMessage(user_chat_message, true)

            // add common reaction
            self.addBotMessages(self.currentItem.common_reaction).then(function(){
              // add filter reaction
              self.addFilterReaction(self.currentItem.filter_reaction).then(function(){
                self.addQuestion().then(function(is){
                  if (is == true){
                    self.completeChat()
                    // add thanks
                    self.addBotMessages(self.flow_chat.thanks)
                  }
                })
              })
            })
          }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'bottom'});
        })
      } 
    },

    inputSubmitted(data){
      var self = this
      //console.log(data)
      this.currentItem.answer = data.user_chat_value
      var user_chat_message = data.user_chat_message

      if (this.nolink == 'true'){
        // hide input
        self.show_input = false

        // add user message
        self.addUserMessage(user_chat_message, true)

        // add common reaction
        self.addBotMessages(self.currentItem.common_reaction).then(function(){
          // add filter reaction
          self.addFilterReaction(self.currentItem.filter_reaction).then(function(){
            self.addQuestion().then(function(is){
              if (is == true){
                // add thanks
                self.addBotMessages(self.flow_chat.thanks)
              }
            })
          })
        })
      }else{
        // send answer to server
        var header = {headers: {'Authorization': this.session, 'Content-Type': 'application/json'}}
        var postData = {
                update:{
                  [this.currentItem.id]: this.currentItem.answer
              }
            }
        chat.sendChat( postData, header).then(response => {
          if(response.data.code == 2000){
            // hide input
            self.show_input = false

            // add user message
            self.addUserMessage(user_chat_message, true)

            // add common reaction
            self.addBotMessages(self.currentItem.common_reaction).then(function(){
              // add filter reaction
              self.addFilterReaction(self.currentItem.filter_reaction).then(function(){
                self.addQuestion().then(function(is){
                  if (is == true){
                    self.completeChat()
                    // add thanks
                    self.addBotMessages(self.flow_chat.thanks)
                  }
                })
              })
            })
          }else{
            //console.log(response.data.mess)
          }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'bottom'});
        })
      } 
    },

    addFilterReaction(){
      var self = this
      return new Promise(function(resolve){
        //console.log(self.currentItem)
        if (self.currentItem.filter_reaction !== undefined && self.currentItem.filter_reaction.length > 0){
          var temp_data = []
          for(var j=0; j < self.currentItem.filter_reaction.length; j++){
            var filter_reaction_item = self.currentItem.filter_reaction[j]


            var pattern = filter_reaction_item.pattern_match

            if (pattern == ""){
              pattern = "^$"
            }
  
            var regex = new RegExp(pattern)

            if (regex.test(self.currentItem.answer)){
              if (filter_reaction_item.reaction !== undefined && filter_reaction_item.reaction.length > 0){

                for(var k=0; k < filter_reaction_item.reaction.length; k++){
                  temp_data.push({type: filter_reaction_item.reaction[k].type, value: filter_reaction_item.reaction[k].value})
                }
              }
            }
          }
          self.addBotMessages(temp_data).then(function(){
            resolve()
          })
        }else{
          resolve()
        }
      })
    },

    completeChat(){
      var self = this
      var header = {headers: {'Authorization': this.session, 'Content-Type': 'application/json'}}
      
      chat.completeChat(header).then(response => {
        if(response.data.code == 2000){
          this.$emit('complete_chat')
        }
      }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'bottom'});
        })
    },

    addUserMessage(value, tracking_flag){
      this.answered_count ++

      if (this.currentItem.answer_type == 'auto_address_by_zipcode'){
        try {
          var answerValue = JSON.parse(value)
          this.addMessage({
                  type: 'text',
                  value: answerValue.zipcode,
                  author: 'user',
                  answer_id: this.answered_count + '_zipcode'
            })
          this.addMessage({
                  type: 'text',
                  value: answerValue.prefecture_name,
                  author: 'user',
                  answer_id: this.answered_count + '_prefecture'
            })
          this.addMessage({
                  type: 'text',
                  value: answerValue.city,
                  author: 'user',
                  answer_id: this.answered_count + '_city'
            })
          this.addMessage({
                  type: 'text',
                  value: answerValue.area,
                  author: 'user',
                  answer_id: this.answered_count + '_area'
            })
          this.addMessage({
                  type: 'text',
                  value: answerValue.prefecture_name + ' ' + answerValue.city + ' ' + answerValue.area,
                  author: 'user',
                  answer_id: this.answered_count + '_prefecture_city_area'
            })
        } catch(e) {
            if (value == ''){
              this.addMessage({
                type: 'text',
                value: "Skip",
                author: 'user',
                answer_id: this.answered_count
              })
            }else{
              this.addMessage({
                type: 'text',
                value: value,
                author: 'user',
                answer_id: this.answered_count
              })
            }
        }
      }else{
        if (value == ''){
          this.addMessage({
            type: 'text',
            value: "Skip",
            author: 'user',
            answer_id: this.answered_count
          })
        }else{
          this.addMessage({
            type: 'text',
            value: value,
            author: 'user',
            answer_id: this.answered_count
          })
        }
      }
      
      if (tracking_flag == true){
        this.addMeasurementScript()
      }

      this.question_ids_answered.push(this.currentItem.id)
      this.answer_data.unshift({key:this.currentItem.label_name, value:this.currentItem.answer, question_id: this.currentItem.id})
      
      this.$emit('chat_progress', this.currentItemIndex + 1)
      //this.updateProgress(this.currentItemIndex + 1)
    },

    addMessage(message){
      //console.log(message)
      this.messages.push(message)
      this.scrollToEnd()
    },

    replace_tag_in_reactions(message){
      for(var i = 0; i < this.answer_data.length; i++){
        message = message.replace( new RegExp('{' + this.answer_data[i].key + '}', 'g') , this.answer_data[i].value)
      }
      return message
    },

    showWave(){
      document.getElementById("wave-message").style.display = "block"
    },
    hideWave(){
      document.getElementById("wave-message").style.display = "none"
    },

    scrollToEnd(){
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
    },

    scrollToEnd_2(){
      this.$nextTick(function() {
        var container = this.$refs.bottomElement
        container.scrollTop = container.scrollHeight
      })
    },

    updateProgress(value){

      if (this.totalQuestion > 0){
        this.progress_value = Math.round(((value) / this.totalQuestion)*100)
      }

      document.querySelector(".v-progress-linear__content").style.marginLeft = this.progress_value + "%";

      if (this.progress_value > 0){
        document.querySelector(".progress-percentage-wrapper").style.marginLeft = '-20px';
      }

      if (this.progress_value > 95){
        document.querySelector(".progress-percentage-wrapper").style.marginLeft = '-60px';
      }
    },

    addMeasurementScript(){
      if (this.currentItem.script_tag != undefined && this.currentItem.script_tag != null && this.currentItem.script_tag != ""){
        let script = this.currentItem.script_tag.replace(/<\/?script.*?>/gi,"")
        try {
          eval(script)
        } catch(err) {
          //console.log("error")
        }
      }
    },

    calculatePadding(){
      setTimeout(function(){
        var height = document.getElementById('input-field').clientHeight
        if (height > 150){
          document.querySelector(".body-inner").style.paddingBottom = height + 20 +'px'
        }else{
           document.querySelector(".body-inner").style.paddingBottom = '150px'
        }
      }, 100);
    }
  },

  updated(){
    this.$nextTick(function() {
      this.scrollToEnd()
    })
    this.calculatePadding()
  },

  watch:{
    bot_setting: function(){
      if (this.bot_setting.message_interval != undefined && this.bot_setting.message_interval != null){
        this.message_interval = this.bot_setting.message_interval
      }

    },

    flow_chat: function(){
    },
    start_chat: function(){
      this.question_map = []
      if (this.flow_chat.body.length > 0){
        for (var i=0 ; i< this.flow_chat.body.length; i++ ){
          this.question_map.push(this.flow_chat.body[i].id)
        }
      }
      this.totalQuestion = this.flow_chat.body.length
      this.startChat()
    },

    messages: function(){
    }
  }

};
</script>
