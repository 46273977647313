<template>
<v-container>
  <v-row no-gutters v-if="is_required == false">
    <v-col 
          cols="auto"
          class="ml-auto mb-1">
      <v-btn text small @click="skip" :style="{color:  bot_setting.main_color}">Skip »</v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col class="pr-2">
      <v-text-field
        ref="input_value"
        v-model="input_value"
        :placeholder="placeholder"
        :error-messages="!checkValid ? error_message: ''"
        solo
        @keyup.enter="submit"
        :autofocus="true"
        :type="input_type"
      ></v-text-field>
      <div>
        <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
          <v-col cols="auto">
            <v-checkbox
                v-model="item.default"
                class="mt-0 confirmation-checkbox"
                :color="bot_setting.main_color"
              >
            </v-checkbox>
          </v-col>
          <v-col class="mt-1">
              <span style="white-space: pre-wrap;" v-html="item.value"></span>
          </v-col>
        </v-row>
        <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
      </div>
    </v-col>
    <v-col 
          cols="auto"
          class="ml-auto mb-auto">
      <v-btn class="margin-top-0375" elevation="3" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>
    </v-col>
  </v-row>

</v-container>
</template>

<script>
export default {

  name: "TextFieldInput",

  props: ['placeholder', 'error_message', 'pattern_match', 'bot_setting', 'is_required', 'answer_type', 'confirmation'],

  data: () =>({
    input_value: "", 
    checkValid: true,
    input_type:'text',
    confirmationValid: true
  }),

  created(){
    if (this.answer_type == 'email'){
      this.input_type = 'email'
    }
    if (this.answer_type == 'phone-number-no-hyphen' || this.answer_type == 'phone-number-hyphen' || this.answer_type == 'phone-number-both'){
      this.input_type = 'tel'
    }
  },


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },
    submit(){
      if (this.validateInput() == true){
        this.checkValid = true
        if (this.validateConfirmation() == true) {
          var emitValue = {user_chat_message: this.input_value, user_chat_value:this.input_value}
          this.$emit('text_input_submitted', emitValue)
          this.input_value = ''
        }
      }else{
        this.confirmationValid = true
        this.checkValid = false
        this.$emit('calculate_padding')
        this.$nextTick(()=>this.$refs.input_value.focus())
      }
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      this.$emit('calculate_padding')
      if (this.confirmationValid == true) {
        return true
      }
      return false
    },

    validateInput(){
      //console.log(this.input_value)
      if (this.is_required == true){
        if (this.input_value == null || this.input_value == ""){
          return false
        }
      }
      if (this.pattern_match != null || this.pattern_match != ""){
        var regex = new RegExp(this.pattern_match)
        if (regex.test(this.input_value)){
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    }
  }

}
</script>