<template>
  <v-app>
    <v-content>
      <Header
          v-if="bot_setting != null"
          v-bind:bot_setting="bot_setting"
          v-bind:progress_value="progress_value"
      />
      <!--Progress bar-->
      <!--<v-row v-if="bot_setting && bot_setting.progress_bar" no-gutters class="chat-progress">
        <v-progress-linear
          v-if="bot_setting != null"
          v-model="progress_value"
          :color="bot_setting.progress_bar_color"
          height="8"
          striped
        >
          <template v-slot="{ value }">
            <strong style="color:#fff; font-size:10px;">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-row>-->
      <!---->
      <Body 
          v-bind:session="session"
          v-bind:flow_chat="flow_chat"
          v-bind:start_chat="start_chat"
          v-bind:bot_setting="bot_setting"
          v-bind:nolink="nolink"

          @complete_chat="completedChat"
          @chat_progress="updateProgress"
      />
      <!--Dialog-->
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="450">
            <template v-slot:activator="{ on }">
            </template>
            <v-card>
              <v-card-title class="headline">お申込途中のデータがあります。</v-card-title>
              <v-card-text>続きから入力されますか？</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="usePreviousData">はい</v-btn>
                <v-btn color="secondary" text @click="createChatSession">いいえ</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template> 
    </v-content>
  </v-app>
</template>

<style>

</style>

<script>
import '@/assets/css/custom.css'
import Header from './components/Header.vue'
import Body from './components/Body.vue'
import Cookies from 'js-cookie'
import chat from '@/services/api/chat.js'

export default {
  name: 'ChatForm',

  components: {
    Header, Body
  },

  created(){
    if(typeof window.MYCHATBOT == "undefined"){
      window.MYCHATBOT = {}
    }
    this.checkDraftChat()
  },

  data: () => ({
    dialog: false,
    
    chat_id: '',
    custom_url: '',
    session: '',

    token: '',
    flow_chat: null,
    bot_setting: null,

    temp_data: null,
    start_chat: false,

    bot_id: '',
    nolink: '',

    progress_value: 0,

    totalQuestion: 0,

    measurement_script: '',
    conversion_script:'',

    unique_user_id: '',
    history_chat: null,
  }),

  methods: {
    checkDraftChat(){
      this.chat_id = this.$route.params.chat_id
      this.bot_id = this.$route.query.bot_id
      this.nolink = this.$route.query.nolink

      this.custom_url = this.$route.params.chat_id

      if (typeof this.bot_id != "undefined"){
        this.chat_id = this.chat_id + this.bot_id
      }

      if (this.nolink == 'true'){
        this.createChatSession()
      }else{
        this.session = Cookies.get(this.chat_id)
        this.history_chat = Cookies.get('history_chat')
        if (this.history_chat === 'true' && this.session && typeof this.session != 'undefined') {
          this.loadChatSession()
        }else{
          this.createChatSession()
        }
      }

    },

    createChatSession(){ 
      var self = this
      self.clearChatSession()
      self.dialog = false

      var postData = {
        custom_url:self.custom_url
      }
      if (self.bot_id != 'undefined'){
        postData.bot_id = self.bot_id
      }

      if (self.nolink != 'undefined'){
        postData.nolink = self.nolink
      }

      if (self.unique_user_id != ''){
        postData.unique_user_id = self.unique_user_id
      }

      chat.createChatSession( postData ).then(response => {
        //console.log(response)
        if(response.data.code == 2000){
          self.token = response.data.token
          self.bot_setting = response.data.data.bot.setting
          self.temp_data = response.data.data.bot
          self.measurement_script = response.data.data.measurement_script
          self.conversion_script = response.data.data.conversion_script
          self.unique_user_id = response.data.data.unique_user_id

          window.MYCHATBOT['session_id'] = response.data.data.session_id
          window.MYCHATBOT['unique_user_id'] = response.data.data.unique_user_id

          if ('history_chat' in response.data.data){
            self.history_chat = response.data.data.history_chat 
          }else{
            self.history_chat = true
          }

          self.saveChatSession()
          self.saveHistoryChat()

          self.startChat()
        }else{
          self.$router.push('/404')
          //console.log(response.data.mess)
        }
      }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'bottom'});
        })
    },

    loadChatSession(){
      var self = this
      self.dialog = false

      var header = {headers: {'Authorization': self.session, 'Content-Type': 'application/json'}}
      
      chat.loadChatSession(header).then(response => {
        if(response.data.code == 2000){
          if (response.data.valid){
            self.bot_setting = response.data.data.bot.setting
            self.temp_data = response.data.data.bot
            self.measurement_script = response.data.data.measurement_script
            self.conversion_script = response.data.data.conversion_script
            self.unique_user_id = response.data.data.unique_user_id

            window.MYCHATBOT['session_id'] = response.data.data.session_id
            window.MYCHATBOT['unique_user_id'] = response.data.data.unique_user_id

            if ('history_chat' in response.data.data){
              self.history_chat = response.data.data.history_chat 
            }else{
              self.history_chat = true
            }
            self.saveHistoryChat()

            if (self.history_chat == true){
              self.dialog = true
            }else{
              self.createChatSession()
            }
          }else{
            self.createChatSession()
          }
        }else{
          self.createChatSession()
        }
      }).catch()

      // .catch(function (error) {
      //       self.$toast.open({message: error, type: 'error', position: 'bottom'});
      //   })
    },

    usePreviousData(){
      this.dialog = false
      this.startChat()
    },

    startChat(){
      this.session = Cookies.get(this.chat_id)
      this.flow_chat = this.temp_data.flow_chat
      this.start_chat = true

      this.totalQuestion = this.flow_chat.body.length
    },

    updateProgress(value){
      this.progress_value = Math.round(((value) / this.totalQuestion)*100)
    },

    saveChatSession(){
      Cookies.set(this.chat_id, this.token)
    },

    saveHistoryChat(){
      Cookies.set('history_chat', this.history_chat)
    },

    clearChatSession(){
      Cookies.remove(this.chat_id)
    },

    completedChat(){
      this.addConversionScript()
      this.clearChatSession()
    },

    addMeasurementScript(){
      if (this.measurement_script != undefined && this.measurement_script != null && this.measurement_script != ""){
        let script = this.measurement_script.replace(/<\/?script.*?>/gi,"")
        try {
          eval(script)
        } catch(err) {
          //console.log("error")
        }
      }
    },

    addConversionScript(){
      if (this.conversion_script != undefined && this.conversion_script != null && this.conversion_script != ""){
        let script = this.conversion_script.replace(/<\/?script.*?>/gi,"")
        try {
          eval(script)
        } catch(err) {
          //console.log("error")
        }
      }
    },


  },
  watch: {
    temp_data: function(){
      
    },

    measurement_script: function(){
      this.addMeasurementScript()
    }
  }
};
</script>
