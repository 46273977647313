<template>
<v-container>
  <v-row no-gutters v-if="is_required == false">
    <v-col 
          cols="auto"
          class="ml-auto mb-1">
      <v-btn text small @click="skip" :style="{color: bot_setting.main_color}">Skip »</v-btn>
    </v-col>
  </v-row>
  <div>
    <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
      <v-col cols="auto">
          <v-checkbox
              v-model="item.default"
              class="mt-0 confirmation-checkbox"
              :color="bot_setting.main_color"
            >
          </v-checkbox>
        </v-col>
        <v-col class="mt-1">
            <span style="white-space: pre-wrap;" v-html="item.value"></span>
        </v-col>
    </v-row>
    <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
  </div>
  <v-row no-gutters justify="center" align="center">

    <v-col 
      cols="auto"
      class="ml-auto">

      <v-btn 
        class="ml-2 mb-2"
        rounded dark outlined 
        :style="{color: bot_setting.main_color}"

        v-for="(item, index) in selection_options" v-bind:key="index"

        @click="submit(index)"
        :disabled="btnDisabled"
      >
        <span class="v-label theme--light">{{item.title}}</span>
      </v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {

  name: "SingleSelectionInput",

  props: ['placeholder', 'error_message', 'bot_setting', 'is_required', 'selection_options', 'confirmation'],

  created(){
    //console.log(this.selection_options)
  },

  data: () =>({
    confirmationValid: true,
    btnDisabled: false
  }),


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },

    submit(data){
      if (this.validateConfirmation() == true) {
        this.btnDisabled = true
        var emitValue = {user_chat_message: this.selection_options[data].title, user_chat_value:this.selection_options[data].value}
        this.$emit('single_selection_input_submitted', emitValue)
      }else{
        this.btnDisabled = false
      }
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      this.$emit('calculate_padding')
      if (this.confirmationValid == true) {
        return true
      }
      return false
    }
  }

}
</script>