<template>
  <v-container>
    <v-row no-gutters v-if="is_required == false">
      <v-col 
            cols="auto"
            class="ml-auto mb-1">
        <v-btn text small @click="skip" :style="{color:  bot_setting.main_color}">Skip »</v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" align="center">

      <v-col class="pr-2">
        <v-text-field
          ref="input_value"
          v-model="input_value"
          :placeholder="placeholder"
          :error-messages="!checkValid ? error_message: ''"
          solo
          @keyup.enter="submit"
          :autofocus="true"
        ></v-text-field>
      </v-col>
      <v-col 
            cols="auto"
            class="ml-auto mb-auto">

        <v-btn class="margin-top-0375" elevation="3" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>
      </v-col>
    </v-row>

    <!--Dialog-->
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="450">
            <template v-slot:activator="{ on }">
            </template>
            <v-card>
              <v-card-text class="pb-0">
                <v-container v-if="addresses != null && addresses.length > 0">
                  <v-row style="font-size:1.2rem; word-break:break-word;">郵便番号から下記の住所が該当しました</v-row>
                  <v-row class="pb-0 mt-5">
                    {{addresses[addressIndex].address1}}<br>
                    {{addresses[addressIndex].address2}}<br>
                    {{addresses[addressIndex].address3}}
                  </v-row>
                  <v-row class="mt-5 mb-0" v-if="addresses.length > 0">正しければ[OK]を押してください。</v-row>
                  <v-row class="mt-2 mb-0" v-if="addresses.length > 1">候補が{{addresses.length}}あります。[キャンセル]で次の住所を表示します。</v-row>
                </v-container>
                <div>
                  <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
                    <v-col cols="auto">
                      <v-checkbox
                          v-model="item.default"
                          class="mt-0 confirmation-checkbox"
                          :color="bot_setting.main_color"
                        >
                      </v-checkbox>
                    </v-col>
                    <v-col class="mt-1">
                        <span style="white-space: pre-wrap; font-size: 1rem;" v-html="item.value"></span>
                    </v-col>
                  </v-row>
                  <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogOk">OK</v-btn>
                <v-btn color="secondary" text @click="dialogCancel">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template> 

  </v-container>
</template>

<script>
import chat from '@/services/api/chat.js'

export default {

  name: "AutoAddressByZipcodeInput",

  props: ['placeholder', 'error_message', 'pattern_match', 'bot_setting', 'is_required', 'sub_label', 'confirmation'],

  data: () =>({
    input_value: null, 
    checkValid: true,
    confirmationValid: true,

    dialog: false,

    addresses: [],
    addressIndex: 0,
  }),


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },
    submit(){
      if (this.validateInput() == true){
        this.fetchAddress()
      }else{
        this.checkValid = false
        this.$nextTick(()=>this.$refs.input_value.focus())
      }
    },

    validateInput(){
      //console.log(this.input_value)
      if (this.is_required == true){
        if (this.input_value == null || this.input_value == ""){
          return false
        }
      }
      if (this.pattern_match != null || this.pattern_match != ""){
        var regex = new RegExp(this.pattern_match)
        if (regex.test(this.input_value)){
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      if (this.confirmationValid == true) {
        return true
      }
      return false
    },

    fetchAddress(){
      var self = this
      chat.getAddress(this.input_value).then(function (response) {
        self.addresses = response.data.data.results

        if (self.addresses != null && self.addresses.length > 0){
          self.addressIndex = 0
          self.dialog = true
        }else{
          self.checkValid = false
        }
      })
      .catch()
    },

    dialogOk(){
      if (this.validateConfirmation() == true) {
        var userSelect = {
          prefecture_name: this.addresses[this.addressIndex].address1,
          city: this.addresses[this.addressIndex].address2,
          area: this.addresses[this.addressIndex].address3,
          prefecture_code: this.addresses[this.addressIndex].prefcode,
          zipcode: this.addresses[this.addressIndex].zipcode,
        }
        var emitValue = {user_chat_message: JSON.stringify(userSelect), user_chat_value:JSON.stringify(userSelect)}
        this.$emit('auto_address_by_zipcode', emitValue)
        this.$emit('calculate_padding')

        this.dialog = false
        this.checkValid = false
      }
    },

    dialogCancel(){
      if (this.addresses.length > 0 && this.addressIndex < this.addresses.length - 1){
        this.addressIndex += 1
        this.confirmationValid = true
      }else{
        this.dialog = false
        this.checkValid = false
        this.confirmationValid = true
      }
    }
  }

}
</script>