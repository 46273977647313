<template>
<v-container>
  <v-row no-gutters v-if="is_required == false">
    <v-col 
          cols="auto"
          class="ml-auto mb-1">
      <v-btn text small @click="skip" :style="{color: bot_setting.main_color}">Skip »</v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col  
      lg="6"
      md="6"
      sm="12"
      xs="12"
      class="ml-auto mr-auto">
      <v-row>
        <v-select class="pr-2"
            v-model="selectedData"
            :items="selection_options"
            item-text="title"
            item-value="value"
            :label="placeholder"
            :error-messages="!checkValid ? error_message: ''"
            return-object
            chips
            multiple
            solo
            :item-color="bot_setting.main_color"
        ></v-select>
    </v-row>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col 
      lg="6"
      md="6"
      sm="12"
      xs="12"
      class="ml-auto mr-auto">
      <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
        <v-checkbox
            v-model="item.default"
            class="mt-0 confirmation-checkbox"
            :color="bot_setting.main_color"
          >
          <template v-slot:label>
            <span style="white-space: pre-wrap;" v-html="item.value"></span>
          </template>
          </v-checkbox>
      </v-row>
      <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-btn elevation="3" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>
  </v-row>

</v-container>
</template>

<style>
.custom-checkbox .v-messages{
  display:none;
}
</style>


<script>
export default {

  name: "DropdownMultiInput",

  props: ['placeholder', 'error_message', 'bot_setting', 'is_required', 'selection_options', 'label_name', 'confirmation'],

  created(){
    this.checkValid = true
  },

  data: () =>({
    selectedData:[],
    checkValid: true,
    confirmationValid: true
    //error_message: 'Please select an option',
  }),


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },

    submit(){
      this.confirmationValid = true
      this.checkValid = true

      var emitValue = {user_chat_message: '', user_chat_value:''}

      for(var i=0; i< this.selectedData.length; i++){
        var item = this.selectedData[i]
          emitValue.user_chat_message += item.title + ','
          emitValue.user_chat_value += item.value + ','
      }

      if (this.selectedData.length == 0){
        this.checkValid = false
        this.confirmationValid = true
        this.$emit('calculate_padding')
        return false
      }
      

      if (this.selectedData.length > 0 && emitValue.user_chat_message.length > 1)
        emitValue.user_chat_message = emitValue.user_chat_message.substring(0, emitValue.user_chat_message.length - 1);

      if (this.selectedData.length > 0 && emitValue.user_chat_value.length > 1)
        emitValue.user_chat_value = emitValue.user_chat_value.substring(0, emitValue.user_chat_value.length - 1);

      if (this.validateConfirmation() == true) {
        this.$emit('dropdown_multi_input_submitted', emitValue)
      }
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      this.$emit('calculate_padding')
      if (this.confirmationValid == true) {
        return true
      }
      return false
    }
  },
  watch:{
    // selectedData: function(){
    //   if (this.selectedData.length > 0){
    //     this.checkValid = true
    //   }
    // }
  }
}
</script>