<template>
	<v-container id="header" class="header elevation-1" fluid :style="{backgroundColor: bot_setting.main_color}">
		<v-row
			align="center"
			justify="center"
		>
			<!--<v-img
				:alt="bot_setting.display_name"
				class="shrink mr-2 pa-2 icon"
				contain
				:src="bot_setting.icon"
				transition="scale-transition"
				width="40"
				height="40"
			/>-->
			<v-avatar  size="40px" class="mr-1 chat-bot-avatar mb-3">
            <img v-if="bot_setting != null" :src="bot_setting.icon">
            </v-avatar>

			<h3 class="mb-3">{{bot_setting.display_name}}</h3>
		</v-row>
			
		<v-row
			align="center"
			justify="center"
			class="description"
			id="chat-description"
		>
			<p class="text-center body-2" v-if="bot_setting && bot_setting.description != ''">
				{{bot_setting.description}}
			</p>
		</v-row>
		<!--Progress bar-->
		<!--<v-row v-if="bot_setting && bot_setting.progress_bar">
			<v-progress-linear
			v-if="bot_setting != null"
			v-model="progress_value"
			:color="bot_setting.progress_bar_color"
			height="8"
			striped
		>
			<template v-slot="{ value }" style="position:absolute; top:0; left:0;">
				<strong style="color:#fff; font-size:10px;">{{ Math.ceil(value) }}%</strong>
			</template>
			</v-progress-linear>
    </v-row>-->
		</v-container>
</template>

<style>
.icon{
	border-radius:50%;
	object-fit: cover;    
	object-position: center;
}

</style>

<script>

export default{
	name: "Header",
	props: ['bot_setting', 'progress_value'],

	created(){
		//console.log(this.bot_setting)
		window.addEventListener("scroll", function() {
			var top = this.scrollY
			if (top > 100){
				document.getElementById("chat-description").style.display = "none"
			}else{
				document.getElementById("chat-description").style.display = "block"
			}
		}, false);
	},

	watch:{
		bot_setting: function(){

		}
	}



}

</script>