import request from '@/utils/http-config';

export default {
	createChatSession(data) {
		return request.post('chat/create_chat_session', data)
	},

	loadChatSession(header) {
		return request.post('chat/load_session', null, header)
	},
	sendChat(data, header){
		return request.post('chat/answer', data, header)
	},
	completeChat(header){
		return request.post('chat/complete', null, header)
	},

	getAddress(zipcode) {
		return request.get('chat/check_zipcode/' + zipcode)
	},
}
